<template>
  <main>
    <div class="medline-wrap">
      <div class="h1">
        <h1>Medicine Line</h1>
        <hr />
        <span></span>
      </div>
      <div class="content">
        <p>
          Для лучшего решения для хранения данных изображений без пленки
          Medicine Line предлагает самые надежные и высококачественные
          решения. Носитель для записи компакт-дисков, специально разработанный
          для медицинских учреждений. Все продукты Medicine Line
          зарегистрированы как медицинские. Это сочетает в себе соответствие
          требованиям DICOM с профессиональными носителями высшего класса. Эта
          комбинация обеспечивает высочайшую производительность чтения / записи
          на всех скоростях в сочетании с отличной совместимостью с системами
          PACS и модульными накопителями, что обеспечивает надежное, стабильное
          качество носителей с увеличенным сроком службы записанных данных о
          пациентах.
        </p>
        <p>
         Medicine Line DVD-R 16x емкостью 4,7 ГБ предлагает самые надежные и 
         высококачественные записываемые носители, специально разработанные для медицинских 
         учреждений для лучшего решения для хранения изображений без пленки. Диск обладает 
         высочайшей производительностью чтения / записи на всех скоростях в сочетании с отличной 
         совместимостью и увеличенным сроком службы записанных данных пациента и поставляется с 
         защитным слоем с твердым покрытием, который обеспечивает в 100 раз большую устойчивость к 
         царапинам по сравнению с обычным DVD-R. DVD-R Medicine Line соответствует требованиям 
         ROHS/REACH, ECMA 379 и 396 и DICOM.
         <br/><br/>
          Доступность поверхностей для печати:<br/>
          • Inkjet White, Smart Guard, Smart White.<br/>
          • Thermal White.<br/>
          • Другое: Пустая блестящая и индивидуальная шелкография. <br/><br/>
          
          Основные преимущества:<br/>
          • Идентификационный код носителя TDK (MID). Гарантированный срок службы архива 30 лет.<br/>
          • Самый низкий уровень ошибок на рынке.<br/>
          • Доказанная высочайшая надежность и долговечность с полной отслеживаемостью.<br/>
          • Широкий выбор вариантов печати и упаковки.<br/>
          • Отличная производительность и совместимость с самыми популярными накопителями.<br/>         
        </p>
      </div>
      <div class="disc-structure">
        <img src="/images/CD-R-MEDILINE-1-1024x346.jpg" alt="">
        <img src="/images/DVD-R-Mediline-1-1024x346.jpg" alt="">
      </div>
      <div class="data-sheet">
        <h2>СПЕЦИФИКАЦИЯ</h2>
        <table>
          <tbody>
            <tr>
              <th>DATA STORAGE SOLUTIONS FOR BUSINESS</th>
              <td>CD-r</td>
              <td>DVD "-" R<br/>
              DVD "+" R<br/>
              DVD DL "-" R</td>
            </tr>
            <tr>
              <th><b>DISC INFORMATION</b></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Speed</th>
              <td>52x</td>
              <td>16x</td>
            </tr>
            <tr>
              <th>Capacity, MB</th>
              <td>700</td>
              <td>4700</td>
            </tr>
            <tr>
              <th>Media Code</th>
              <td>TDK</td>
              <td>TDK</td>
            </tr>
            <tr>
              <th>Time Code</th>
              <td>97m15s05f - 79m59s74f</td>
              <td>TDK TTH02 </td>
            </tr>
            <tr>
              <th>Safe Storage (Years)</th>
              <td>50</td>
              <td>50</td>
            </tr>
            <tr>
              <th>Estimated shelf life under office condition</th>
              <td>30 Years - ECMA 396</td>
              <td>30 Years - ECMA 396</td>
            </tr>
            <tr>
              <th></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Phtalocianine Light Green Dye</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th><b>CERTIFICATIONS</b></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Archival Line Certifitation</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>LNE Certification</th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>ROHS / REACH Compliant</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>CE Sertification</th>
              <td></td>
              <td>+</td>
            </tr>
            <tr>
              <th>DICOM Compliant</th>
              <td></td>
              <td>+</td>
            </tr>
            <tr>
              <th>ECMA 366 & 379 Compliance</th>
              <td></td>
              <td>+</td>
            </tr>
            <tr>
              <th></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th><b>PRINT OPTIONS</b></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Smart Guard</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Smart White</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Inkjet Water Repelent</th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Inkjet White</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Inkjet Silver Pearl</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Smart Glossy</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Thermal White</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Thermal Silver</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Custom Printing</th>
              <td>+</td>
              <td>+</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
$ml: #52ab62;
.disc-structure {
  text-align: center;
  margin-top: 30px;
}
.data-sheet {
  margin-top: 40px;
  h2 {
    text-align: center;
    font-size: 45px;
  }
  table {
    width: 100%;
    margin-top: 40px;

    tr {
      th {
        text-align: left;
        border-bottom: 1px solid grey;
        padding: 3px;

        b {
          font-weight: bolder;
          font-size: 20px;
          color: $ml;
        }
      }

      td {
        width: 300px;
        border-bottom: 1px solid grey;
        border-left: 1px solid grey;
        padding: 3px;
        text-align: center;
      }
    }
  }
}

main {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
}
.medline-wrap {
  // background-color: white;
  max-width: 1280px;
  margin: 0 auto;
  .h1 {
    h1 {
      font-size: 45px;
      color: $ml;
    }
    hr {
      border: 6px solid $ml;
      background-color: $ml;
    }
  }
  .content {
    margin-top: 30px;
    p {
      line-height: 1.5em;
      font-size: 26px;
    }
  }
}
</style>

<script>
export default {
  name: "MedicineLineComponent",
  data() {
    return {
      rrer: "",
    };
  },
  // components: {
  //   ByeWorld
  // },
  methods: {
    iclick() {
      this.rrer = "";
    },
  },
};
</script>


